import React from 'react';
import Title from '../components/Title';


import LegalCode from '../components/LegalCode';



function Lawtlin() {
  return (
    <div>
      <Title text="Legal Programming with Kotlin"/>
      <LegalCode />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Lawtlin;